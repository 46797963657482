var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
const Parent = (_a) => {
    var { className, onClick, style, children, onDoubleClick, title, ref, onScroll } = _a, props = __rest(_a, ["className", "onClick", "style", "children", "onDoubleClick", "title", "ref", "onScroll"]);
    return (_jsx("div", Object.assign({ ref: ref, className: className, style: style, onClick: onClick, onScroll: onScroll, title: title, onDoubleClick: onDoubleClick }, props, { children: children }), void 0));
};
export default Parent;
