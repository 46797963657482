import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Routes from './app/Routers/Routes';
import SuccessPopUp from './app/Component/SuccessPopUp/SuccessPopUp';
import { Parent } from './app/Component';
export const AppContext = React.createContext({
    showSuccessPopup: () => { },
    itemsFilterCategory: [],
    setItemsFilterCategory: () => { }
});
const App = () => {
    const [showSuccess, setShowSuccess] = useState(false);
    const [successData, setSuccessData] = useState({ title: '', description: '' });
    const [itemsFilterCategory, setItemsFilterCategory] = useState([]);
    const history = useHistory();
    const showSuccessPopup = (title, description) => {
        setSuccessData({ title, description });
        setShowSuccess(true);
        setTimeout(() => {
            setShowSuccess(false);
        }, 5000);
    };
    useEffect(() => {
        const unlisten = history.listen(() => {
            setShowSuccess(false);
        });
        return () => {
            unlisten();
        };
    }, [history]);
    return (_jsx(AppContext.Provider, Object.assign({ value: {
            showSuccessPopup,
            itemsFilterCategory,
            setItemsFilterCategory
        } }, { children: _jsxs("div", { children: [_jsx(Parent, Object.assign({ className: " right-10 top-14 absolute flex justify-end z-10  " }, { children: showSuccess && (_jsx(SuccessPopUp, { title: successData.title, description: successData.description, setShowSuccess: setShowSuccess }, void 0)) }), void 0), _jsx(Routes, {}, void 0)] }, void 0) }), void 0));
};
export default App;
