var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Parent } from '..';
const TextArea = (_a) => {
    var { name, className, id, row, value, placeHolder, defaultValue, onChange, onBlur, onClick } = _a, props = __rest(_a, ["name", "className", "id", "row", "value", "placeHolder", "defaultValue", "onChange", "onBlur", "onClick"]);
    return (_jsx(Parent, { children: _jsx(Parent, Object.assign({ className: "mt-1" }, { children: _jsx("textarea", Object.assign({ name: name, id: id, rows: row, placeholder: placeHolder, value: value, className: className, onBlur: onBlur, onChange: onChange, onClick: onClick, defaultValue: defaultValue }, props), void 0) }), void 0) }, void 0));
};
export default TextArea;
