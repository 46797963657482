/** @format */
import { UserIcon, TagIcon, AdjustmentsIcon, ClockIcon, CogIcon, UserGroupIcon, NewspaperIcon, BookmarkIcon } from '@heroicons/react/solid';
const currencySymbol = localStorage.getItem('symbol') ? localStorage.getItem('symbol') : '£';
export const SIZE = [
    {
        id: 1,
        size1: ' XXL ',
        size2: ' XL ',
        size3: ' M ',
        size4: ' S '
    }
];
export const EMPLOYEEATTENDANCE = [
    {
        id: 1,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    },
    {
        id: 2,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '1'
    },
    {
        id: 3,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    },
    {
        id: 4,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    },
    {
        id: 5,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    },
    {
        id: 6,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    },
    {
        id: 7,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    },
    {
        id: 8,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '1'
    },
    {
        id: 9,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    },
    {
        id: 10,
        Image: require('../assets/media/girl.jpg'),
        EmployeeName1: ' Rene Jose  ',
        EmployeeName2: ' Biller ',
        EmployeeID: 'SR12345',
        LoginTime: '10:53 am',
        LogoutTime: '06:00 pm',
        AttendanceEntry: '0'
    }
];
export const TAX = [
    {
        id: 1,
        name: 'Central GST (CGST)',
        per: '5%'
    },
    {
        id: 2,
        name: 'State GST (CGST)',
        per: '10%'
    },
    {
        id: 3,
        name: 'Central GST (CGST)',
        per: '5%'
    },
    {
        id: 4,
        name: 'Central GST (CGST)',
        per: '5%'
    }
];
export const PAGESIZE = [
    {
        label: '5',
        value: '5'
    },
    {
        label: '10',
        value: '10'
    },
    {
        label: '25',
        value: '25'
    },
    {
        label: '50',
        value: '50'
    },
    {
        label: '100',
        value: '100'
    },
    {
        label: '200',
        value: '200'
    },
    {
        label: '250',
        value: '250'
    }
];
export const DATALIMIT = 10;
export const PAGELIMIT = 5;
export const STARTPAGE = 1;
export const GLOBALDATALIMIT = 100;
export const CLIENTURL = process.env.SERVER_URL_GRAPHQL;
export const SERVERURL = process.env.SERVER_URL;
// export const CLIENTURL = 'http://192.168.0.105:8004/graphql';
// export const SERVERURL = 'http://192.168.0.105:8004/';
// GraphQl Query url
// export const CLIENTURL = 'https://restaurant.eezzypos.com/graphql';
// export const CLIENTURL = "http://192.168.0.115:8001/graphql";
// export const CLIENTURL = 'http://192.168.1.7:8003/graphql';
// export const CLIENTURL = 'https://dev-restaurant.eezzypos.com/graphql';
// GraphQl Query url
// export const SERVERURL = 'https://restaurant.eezzypos.com/';
// export const SERVERURL = 'http://192.168.1.7:8003/';
// export const SERVERURL = "http://192.168.0.115:8003/";
// export const SERVERURL = 'https://dev-restaurant.eezzypos.com/';
export const NAVIGATIONSETTINGSARRAY = [
    {
        id: 1,
        title: 'General',
        icon: CogIcon,
        link: '/general'
    },
    {
        id: 2,
        title: 'Outlet Details',
        icon: TagIcon,
        link: '/outlet-details'
    },
    {
        id: 3,
        title: 'Configuration',
        icon: AdjustmentsIcon,
        link: '/config'
    },
    {
        id: 4,
        title: 'Outlet Timings',
        icon: ClockIcon,
        link: '/outlet-timings'
    },
    {
        id: 5,
        title: 'Users',
        icon: UserIcon,
        link: '/users'
    },
    {
        id: 6,
        title: 'Employees',
        icon: UserGroupIcon,
        link: '/emplyoee'
    },
    {
        id: 7,
        title: 'POS Session',
        icon: NewspaperIcon,
        link: '/pos-session'
    },
    {
        id: 9,
        title: 'Loyalty Setting',
        icon: BookmarkIcon,
        link: '/loyalty-settings'
    }
];
export const COUPONSFIELDS = {
    table: 'ps_m_coupons',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Coupon_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'ID',
            type: 'text'
        },
        {
            'field name': 'coupon_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Coupon Name',
            type: 'text'
        },
        {
            'field name': 'valid_from',
            'field type': 'date',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: false,
            display_name: 'Valid From',
            type: 'date'
        },
        {
            'field name': 'valid_till',
            'field type': 'date',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Valid Date',
            type: 'date'
        },
        {
            'field name': 'disc_type_id',
            'field type': 'int',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'ps_m_disctype',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: false,
            display_name: 'Discount Type',
            type: 'select'
        },
        {
            'field name': 'disc_value',
            'field type': 'float',
            'field update': 'yes',
            length: 5,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: false,
            display_name: 'Discount Value',
            type: 'text'
        },
        {
            'field name': 'apply_tax_after_disc',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '0',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: false,
            show_in_ui: false,
            display_name: 'Apply discount after taxes',
            type: 'checkbox'
        },
        {
            'field name': 'status',
            'field type': 'varchar',
            'field update': 'no',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: false,
            edit: false,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Status',
            type: 'text'
        },
        {
            'field name': 'redemptions',
            'field type': 'int',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'ps_m_outlet',
            create: false,
            edit: false,
            list: true,
            filter: false,
            show_in_ui: true,
            display_name: 'Redemptions',
            type: 'text'
        },
        {
            'field name': 'outlet_id',
            'field type': 'int',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'ps_m_outlet',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Outlets',
            type: 'text'
        },
        {
            'field name': 'business_id',
            'field type': 'int',
            'field update': 'no',
            length: 10,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'ps_m_business',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Business',
            type: 'text'
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
// user page
export const USERFIELDS = {
    table: 'ps_m_users',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'User_Master',
    is_flex: 'yes',
    unique_key: ['state_id,district_name'],
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'ID',
            ui_type: 'text'
        },
        {
            'field name': 'first_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'First Name',
            ui_type: 'text'
        },
        {
            'field name': 'last_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Last Name',
            ui_type: 'text'
        },
        {
            'field name': 'email',
            'field type': 'varchar',
            'field update': 'yes',
            length: 50,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Email',
            ui_type: 'text'
        },
        {
            'field name': 'password',
            'field type': 'varchar',
            'field update': 'no',
            length: 5,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: true,
            display_name: 'Password',
            ui_type: 'password'
        },
        {
            'field name': 'mobile',
            'field type': 'varchar',
            'field update': 'yes',
            length: 10,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: true,
            display_name: 'Mobile',
            ui_type: 'text'
        },
        {
            'field name': 'profile_img',
            'field type': 'mediumtext',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Profile',
            ui_type: 'image'
        },
        {
            'field name': 'role_id',
            'field type': 'int',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'yes',
            null: 'not null',
            default: '',
            query: 'getAllRoles{id,name,u_ts,c_ts,is_active}',
            extra: 'ps_m_roles',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Role',
            ui_type: 'dropdown'
        },
        {
            'field name': 'outlet_id',
            'field type': 'int',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'yes',
            null: 'not null',
            default: '',
            query: '',
            extra: 'ps_m_outlet',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Outlets',
            ui_type: 'hidden'
        },
        {
            'field name': 'business_id',
            'field type': 'int',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'yes',
            null: 'not null',
            default: '',
            query: '',
            extra: 'ps_m_business',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Business',
            ui_type: 'hidden'
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            query: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Active',
            ui_type: 'dropdown'
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: 'current timestamp',
            extra: '',
            create: false,
            edit: false,
            list: true,
            filter: false,
            show_in_ui: true,
            display_name: 'Created Date',
            ui_type: 'date'
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Updated Date',
            ui_type: 'date'
        }
    ]
};
// tax
export const TAXESFIELDS = {
    table: 'ps_m_tax',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Tax_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'tax_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Tax Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'tax_pct',
            'field type': 'varchar',
            'field update': 'yes',
            length: 5,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Percentage (%)',
            show_in_ui: true,
            ui_type: 'number',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'tax_label',
            'field type': 'varchar',
            'field update': 'yes',
            length: 15,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Tax Label',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const MISCELLANEOUS = {
    table: 'ps_m_tax',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Miscellaneous_master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'percentage',
            'field type': 'varchar',
            'field update': 'yes',
            length: 5,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: `${'Amount'} (${currencySymbol})`,
            show_in_ui: true,
            ui_type: 'number',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const MAINCATEGORY = {
    table: 'ps_m_tax',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Maincategory_master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'order_by',
            'field type': 'varchar',
            'field update': 'yes',
            length: 5,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: `${'Order By'}`,
            show_in_ui: true,
            ui_type: 'number',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const CARDCONFIG = {
    table: 'ps_m_tax',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'CardConfig_master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Serial Number',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'percentage',
            'field type': 'varchar',
            'field update': 'yes',
            length: 5,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Ip Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const VARIATIONSFIELDS = {
    table: 'ps_m_variation',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Variation_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'v_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Variation Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'description',
            'field type': 'varchar',
            'field update': 'yes',
            length: 50,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Description',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: false
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const ADDONSFIELDS = {
    table: 'ps_m_addon',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Addon_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Addon Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'description',
            'field type': 'varchar',
            'field update': 'yes',
            length: 50,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Description',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: false
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const UOMSFIELDS = {
    table: 'ps_m_uom',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'UOM_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'UOM Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'description',
            'field type': 'varchar',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Description',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const UNITESFIELDS = {
    table: 'ps_m_unit',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Unit_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'unit',
            'field type': 'varchar',
            'field update': 'yes',
            length: 10,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Unit Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'description',
            'field type': 'varchar',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Description',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const EMPTYPESFIELDS = {
    table: 'ps_m_emptype',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'emp_type_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'emp_type',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Employment Type',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const EMPROELSFIELDS = {
    table: 'ps_m_roles',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'Id',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Role Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'display_as',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Display Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'description',
            'field type': 'varchar',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Notes',
            show_in_ui: false,
            ui_type: 'text',
            create: true,
            edit: true,
            list: false,
            filter: true
        },
        {
            'field name': 'allow_pos',
            'field type': 'varchar',
            'field update': 'yes',
            length: '',
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Allow POS Login',
            show_in_ui: true,
            ui_type: 'checkbox',
            create: true,
            edit: true,
            list: true,
            filter: false
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: '',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            display_name: 'Created Date',
            extra: '',
            show_in_ui: true,
            ui_type: '',
            create: false,
            edit: false,
            list: true,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: '',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const ROLESFIELDS = {
    table: 'ps_m_roles',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'Id',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Role Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'display_as',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Display Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'description',
            'field type': 'varchar',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Notes',
            show_in_ui: false,
            ui_type: 'text',
            create: true,
            edit: true,
            list: false,
            filter: true
        },
        {
            'field name': 'description',
            'field type': 'varchar',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Notes',
            show_in_ui: false,
            ui_type: 'text',
            create: true,
            edit: true,
            list: false,
            filter: true
        },
        {
            'field name': 'allow_pos',
            'field type': 'varchar',
            'field update': 'yes',
            length: '',
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Allow POS Login',
            show_in_ui: true,
            ui_type: 'checkbox',
            create: true,
            edit: true,
            list: false,
            filter: false
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: '',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Date',
            show_in_ui: true,
            ui_type: '',
            create: false,
            edit: false,
            list: true,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: '',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const CATEGORIESFIELDS = {
    table: 'ps_m_category',
    fields: [
        {
            'field name': 'cat_image',
            'field type': 'mediumtext',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Image',
            show_in_ui: true,
            ui_type: 'uploadImage',
            create: true,
            edit: true,
            list: true,
            filter: false
        },
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: true,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'cat_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Category Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'tag',
            'field type': 'varchar',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'tag',
            show_in_ui: false,
            ui_type: 'text',
            create: true,
            edit: true,
            list: false,
            filter: true
        },
        {
            'field name': 'is_pos_on',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '1',
            extra: '',
            display_name: 'ON/OFF',
            show_in_ui: true,
            ui_type: 'switch',
            create: true,
            edit: true,
            list: true,
            filter: false
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: '',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: '',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: '',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const CUSTOMERSFIELDS = {
    table: 'ps_m_customers',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'Id',
            show_in_ui: false,
            ui_type: '',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'first_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'First Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'last_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            display_name: 'Last Name',
            show_in_ui: false,
            ui_type: 'text',
            create: true,
            edit: true,
            list: false,
            filter: true
        },
        {
            'field name': 'email',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: false,
            display_name: 'Email',
            ui_type: 'text'
        },
        {
            'field name': 'mobile',
            'field type': 'varchar',
            'field update': 'yes',
            length: 12,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: true,
            display_name: 'Mobile',
            ui_type: 'text'
        },
        {
            'field name': 'profile_img',
            'field type': 'varchar',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'null',
            default: '',
            extra: '',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Profile Image',
            ui_type: 'text'
        },
        {
            'field name': 'dob',
            'field type': 'datetime',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: 'current timestamp',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: false,
            display_name: 'Date Of Birth',
            ui_type: 'text'
        },
        {
            'field name': 'address1',
            'field type': 'varchar',
            'field update': 'yes',
            length: 150,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: false,
            display_name: 'Address 1',
            ui_type: 'textarea'
        },
        {
            'field name': 'address2',
            'field type': 'varchar',
            'field update': 'yes',
            length: 150,
            key: '',
            is_join: 'no',
            null: 'null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: false,
            display_name: 'Address 2',
            ui_type: 'textarea'
        },
        {
            'field name': 'anniversaary',
            'field type': 'varchar',
            'field update': 'yes',
            length: 150,
            key: '',
            is_join: 'no',
            null: 'null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: false,
            display_name: 'Anniversaary',
            ui_type: 'text'
        },
        {
            'field name': 'is_favorite',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '0',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Favourite',
            ui_type: 'checkbox'
        },
        {
            'field name': 'do_not_disturb',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: false,
            display_name: 'Do not disturb',
            ui_type: 'checkbox'
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: '',
            ui_type: ''
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            create: false,
            edit: false,
            list: true,
            filter: false,
            show_in_ui: true,
            display_name: 'Created Date',
            ui_type: ''
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: '',
            ui_type: ''
        }
    ]
};
export const PAYMENTTYPESFIELDS = {
    table: 'ps_m_paytype',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'paytype_Master',
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            display_name: 'ID',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 15,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            display_name: 'Payment Name',
            show_in_ui: true,
            ui_type: 'text',
            create: true,
            edit: true,
            list: true,
            filter: true
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            display_name: 'Is Active',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: '',
            display_name: 'Created Date',
            show_in_ui: true,
            ui_type: 'text',
            create: false,
            edit: false,
            list: true,
            filter: false
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            display_name: 'Updated Time Stamp',
            show_in_ui: false,
            ui_type: 'text',
            create: false,
            edit: false,
            list: false,
            filter: false
        }
    ]
};
export const EMPLOYEESFIELDS = {
    table: 'ps_m_employee',
    tbltype: 'master',
    allowUploadFlg: false,
    download_format_name: 'Employee_Master',
    is_flex: 'yes',
    unique_key: ['email'],
    fields: [
        {
            'field name': 'id',
            'field type': 'intPK',
            'field update': 'no',
            length: null,
            key: 'primary key',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: 'auto number',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'ID',
            type: 'text'
        },
        {
            'field name': 'emp_id',
            'field type': 'varchar',
            'field update': 'yes',
            length: '',
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: false,
            display_name: 'Employee ID',
            type: 'text'
        },
        {
            'field name': 'first_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'First Name',
            type: 'text'
        },
        {
            'field name': 'last_name',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Last Name',
            type: 'text'
        },
        {
            'field name': 'email',
            'field type': 'varchar',
            'field update': 'yes',
            length: 35,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: true,
            display_name: 'Email',
            type: 'text'
        },
        {
            'field name': 'mobile',
            'field type': 'varchar',
            'field update': 'yes',
            length: 12,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: true,
            show_in_ui: true,
            display_name: 'Mobile',
            type: 'text'
        },
        {
            'field name': 'profile_img',
            'field type': 'mediumtext',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: false,
            show_in_ui: true,
            display_name: 'Profile',
            type: 'image'
        },
        {
            'field name': 'id_proof',
            'field type': 'mediumtext',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'ID Proof',
            type: 'image'
        },
        {
            'field name': 'dob',
            'field type': 'date',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Date of Birth',
            type: 'date'
        },
        {
            'field name': 'joining_date',
            'field type': 'date',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Joining Date',
            type: 'date'
        },
        {
            'field name': 'leaving_date',
            'field type': 'date',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Leaving Date',
            type: 'date'
        },
        {
            'field name': 'address',
            'field type': 'varchar',
            'field update': 'yes',
            length: 150,
            key: '',
            is_join: 'no',
            null: '',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Address',
            type: 'text'
        },
        {
            'field name': 'emp_t_id',
            'field type': 'int',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'yes',
            null: 'not null',
            default: '',
            extra: 'ps_m_emptype',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Employee Type',
            type: 'select'
        },
        {
            'field name': 'payment_by',
            'field type': 'varchar',
            'field update': 'yes',
            length: 25,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '',
            extra: '',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Payment By',
            type: 'text'
        },
        {
            'field name': 'role_id',
            'field type': 'int',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'yes',
            null: 'not null',
            default: '',
            extra: 'ps_m_roles',
            create: true,
            edit: true,
            list: true,
            filter: true,
            show_in_ui: true,
            display_name: 'Role',
            type: 'select'
        },
        {
            'field name': 'outlet_id',
            'field type': 'int',
            'field update': 'yes',
            length: null,
            key: '',
            is_join: 'yes',
            null: 'not null',
            default: '',
            extra: 'ps_m_outlet',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Outlets',
            type: 'select'
        },
        {
            'field name': 'business_id',
            'field type': 'int',
            'field update': 'yes',
            length: 100,
            key: '',
            is_join: 'yes',
            null: 'not null',
            default: '',
            extra: 'ps_m_business',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Business',
            type: 'select'
        },
        {
            'field name': 'is_active',
            'field type': 'enum',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: '1',
            extra: '',
            create: true,
            edit: true,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Active',
            type: 'select'
        },
        {
            'field name': 'c_ts',
            'field type': 'datetime',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: '',
            default: 'current timestamp',
            extra: '',
            create: false,
            edit: false,
            list: true,
            filter: false,
            show_in_ui: true,
            display_name: 'Created Date',
            type: 'date'
        },
        {
            'field name': 'u_ts',
            'field type': 'timestamp',
            'field update': 'no',
            length: null,
            key: '',
            is_join: 'no',
            null: 'not null',
            default: 'current timestamp',
            extra: 'on update current_timestamp',
            create: false,
            edit: false,
            list: false,
            filter: false,
            show_in_ui: false,
            display_name: 'Updated Date',
            type: 'date'
        }
    ]
};
export const HEADERS = [
    [
        'Name',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30'
    ]
];
export const Payment = [
    {
        label: 'Select Payment',
        value: '',
        hidden: true
    },
    {
        label: 'Daily',
        value: 'Daily'
    },
    {
        label: 'Weekly',
        value: 'Weekly'
    },
    {
        label: 'Monthly',
        value: 'Monthly'
    }
];
export const seviority = [
    {
        label: 'Select Severity',
        value: '',
        hidden: true
    },
    {
        label: 'Critical ',
        value: 'Critical'
    },
    {
        label: 'Urgent ',
        value: 'Urgent'
    },
    {
        label: 'High ',
        value: 'High'
    },
    {
        label: 'Normal ',
        value: 'Normal'
    },
    {
        label: 'Low ',
        value: 'Low'
    }
];
export const priority = [
    {
        label: 'Select Priority',
        value: '',
        hidden: true
    },
    {
        label: 'High ',
        value: 'High'
    },
    {
        label: 'Normal ',
        value: 'Normal'
    },
    {
        label: 'Low ',
        value: 'Low'
    }
];
export const businessTypes = [
    {
        label: 'Select BusinessType',
        value: '',
        hidden: true
    },
    {
        label: 'Retail ',
        value: 'Retail'
    },
    {
        label: 'Restaurant ',
        value: 'Restaurant'
    }
];
export const miscellaneous = [
    {
        id: 1,
        name: 'Packing',
        pct: 10,
        is_enable: '0'
    },
    {
        id: 2,
        name: 'Delevery',
        pct: 15,
        is_enable: '1'
    },
    {
        id: 3,
        name: 'container',
        pct: 14,
        is_enable: '0'
    }
];
export const items = [
    {
        id: 1,
        subCategoryName: 'food',
        count: '0'
    },
    {
        id: 2,
        subCategoryName: 'drinks',
        count: '0'
    },
    {
        id: 3,
        subCategoryName: 'snacks',
        count: '0'
    },
    {
        id: 4,
        subCategoryName: 'breakfast',
        count: '0'
    }
];
