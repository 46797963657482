// /** @format */
import axios from 'axios';
import { SERVERURL } from '../app/Utilities/Constants';
import { toast } from 'react-hot-toast';
const instance = axios.create({
    baseURL: SERVERURL
});
instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response) {
        const errorCode = error.response.data.code;
        const errorMessage = error.response.data.message;
        console.log('error.response.data:', error.response.data.message);
        if (errorCode && errorMessage) {
            toast.error(`Error ${errorCode}: ${errorMessage}`);
        }
        else {
            toast.error(` ${error.response.status} - ${JSON.stringify(errorMessage)}`);
        }
    }
    else if (error.request) {
        console.error('No response received:', error.request);
        toast.error('No response received: ' + JSON.stringify(error.request));
    }
    else {
        console.error('Request Error:', error.message);
        toast.error('Request Error: ' + error.message);
    }
    return Promise.reject(error);
});
export default {
    GetServerCall: (URL, methodType, token, params) => instance({
        method: methodType,
        url: URL,
        params: params,
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            token: token
        }
    }),
    PostServerCall: (URL, methodType, token, params) => instance({
        method: methodType,
        url: URL,
        data: params,
        headers: {
            'content-type': 'application/json',
            Accept: 'application/json',
            token: token
        }
    })
};
