import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/** @format */
import React from 'react';
import { Parent, Text, Icon } from '..';
const Input = React.forwardRef((props, ref) => {
    return (_jsxs(Parent, { children: [props.labelRequired ? (_jsxs(Parent, { className: "flex items-center", children: [_jsx(Text, { htmlFor: props.id, className: props.labelClass, as: "label", label: props.labelText }), props.astrix === 'not null' ? (_jsx(Text, { as: "label", className: "block text-sm ml-1 font-medium text-red-700", children: "*" })) : ('')] })) : (''), _jsxs(Parent, { className: " relative", children: [props.iconRequired ? (_jsx(Parent, { className: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none", children: _jsx(Icon, { tag: props.iconTag, className: "h-5 w-5 text-gray-300", "aria-hidden": "true" }) })) : (''), _jsx("input", Object.assign({ type: props.type, name: props.name, value: props.value, ref: (el) => (ref = el), id: props.id, className: props.className, placeholder: props.placeHolder }, props, { onBlur: props.onBlur, onChange: props.onChange, onClick: props.onClick, required: props.required, accept: props.accept, onKeyUp: props.onKeyUp, checked: props.checked, disabled: props.disabled, autoFocus: false, min: props.type == 'number' ? 0 : '' }))] })] }));
});
export default Input;
