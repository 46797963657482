var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs } from "react/jsx-runtime";
const TextProps = (_a) => {
    var { as = 'p', label, className, children, htmlFor, onClick, title } = _a, props = __rest(_a, ["as", "label", "className", "children", "htmlFor", "onClick", "title"]);
    const CustomTag = `${as}`;
    return (_jsxs(CustomTag, Object.assign({ htmlFor: htmlFor, className: className, onClick: onClick, title: title }, props, { children: [label, children] }), void 0));
};
export default TextProps;
