import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { toast } from 'react-hot-toast';
import { CLIENTURL } from '../../Utilities/Constants';
import { onError } from '@apollo/client/link/error';
const httpLink = createHttpLink({
    uri: CLIENTURL
});
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: Object.assign(Object.assign({}, headers), { token: token ? `${token}` : '' })
    };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
            console.error('GraphQL Error:', message);
            toast.error('GraphQL Error: ' + message);
        });
    }
    if (networkError) {
        console.error('Network Error:', networkError);
        toast.error('Network Error: ' + networkError.message);
    }
});
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    }
};
export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
});
