var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
const Image = (_a) => {
    var { src, className, alt = '', onClick, onError, title } = _a, props = __rest(_a, ["src", "className", "alt", "onClick", "onError", "title"]);
    return (_jsx("img", Object.assign({ src: src, className: className, alt: alt, title: title, onClick: onClick, onError: onError }, props), void 0));
};
export default Image;
